import React from 'react'
import uuid from 'uuid/v4'
import EmbedContainer from 'react-oembed-container'
import ArticleHeader from 'components/ArticleHeader'
import { getImageUrl, getAltText, stripOfficial } from 'src/commonFunctions'
import ChapterCard from 'components/ChapterCard'
import InnerHtml from 'components/atoms/InnerHtml'
import ShareContent from 'components/ShareContent'
import { intl } from 'src/constants'

export default ({ date, ogp, index, genre, title, isOfficial, sharePath }) => (
  <article className="index_interview_article">
    <ArticleHeader
      date={date}
      isOfficial={stripOfficial(isOfficial)}
      genre={genre}
    />
    <div className="header">
      <h2>{title}</h2>
      <ShareContent title={title} sharePath={sharePath} />
      <EmbedContainer markup={index.about}>
        <InnerHtml className="about" html={index.about} />
      </EmbedContainer>

      <div className="top_image">
        <img lazyload src={getImageUrl(ogp)} alt={getAltText(ogp)} />
      </div>
    </div>
    <section className="index">
      <h3>{intl.getMessage('table_of_contents')}</h3>
      {index.item.map(node => (
        <ChapterCard
          key={uuid()}
          num={node.num}
          url={node.link ? node.link.url : null}
          title={node.title}
          date={node.date}
        />
      ))}
    </section>
  </article>
)
