import React from 'react'
import Helmet from 'react-helmet'
import { SITE_TITLE, MENUS } from 'src/constants'
import ArticleLayout from 'components/ArticleLayout'
import IndexInterviewArticle from 'components/IndexInterviewArticle'
import Footer from 'components/Footer'

export default ({ pageContext }) => {
  const {
    type,
    articleBaseInfo,
    recommendArticles,
    sharePath,
    index,
    content,
    adbanners,
  } = pageContext
  const { title, tags, ogpSettings, isOfficial } = articleBaseInfo

  return (
    <>
      <Helmet title={`${title} | ${SITE_TITLE}`} />
      <ArticleLayout
        type={type}
        articleBaseInfo={articleBaseInfo}
        recommendArticles={recommendArticles}
        sharePath={sharePath}
        adbanners={adbanners}
      >
        <IndexInterviewArticle
          genre={MENUS.SERIES}
          title={title}
          tags={tags}
          date={articleBaseInfo.modified}
          index={index}
          ogp={ogpSettings.ogp}
          content={content}
          isOfficial={isOfficial}
          sharePath={sharePath}
        />
      </ArticleLayout>
      <Footer tags={pageContext.tags} />
    </>
  )
}
